import React from 'react'
import styled from 'styled-components'
import tokens from '@te-digi/styleguide-tokens'

import {
  EmploymentPlanSvg,
  FourZeroFourSvg,
  FourZeroOneSvg,
  Image,
  JobSearchStartFiSvg,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TePalvelutFiSvg,
  Text,
  TyomarkkinatoriAsiantuntijaFiWhiteSvg,
  TyomarkkinatoriEnPrimarySvg,
  TyomarkkinatoriEnWhiteSvg,
  TyomarkkinatoriFiPrimarySvg,
  TyomarkkinatoriFiWhiteSvg,
  TyomarkkinatoriSvPrimarySvg,
  TyomarkkinatoriSvWhiteSvg
} from '@te-digi/styleguide'

import fourZeroOneSvg from '@te-digi/styleguide/assets/401.svg'
import fourZeroFourSvg from '@te-digi/styleguide/assets/404.svg'
import employmentPlanSvg from '@te-digi/styleguide/assets/employment-plan.svg'
import faviconPng from '@te-digi/styleguide/assets/favicon.png'
import faviconAsiantuntijaPng from '@te-digi/styleguide/assets/favicon-asiantuntija.png'
import homeContentBackgroundJpg from '@te-digi/styleguide/assets/home-content-background.jpg'
import homeImageSectionJpg from '@te-digi/styleguide/assets/home-image-section.jpg'
import jobSearchStartFiSvg from '@te-digi/styleguide/assets/job-search-start-fi.svg'
import tePalvelutFiSvg from '@te-digi/styleguide/assets/te-palvelut-fi.svg'
import tyomarkkinatoriAsiantuntijaSvg from '@te-digi/styleguide/assets/tyomarkkinatori-asiantuntija.svg'
import tyomarkkinatoriEnPrimarySvg from '@te-digi/styleguide/assets/tyomarkkinatori-en-primary.svg'
import tyomarkkinatoriEnSvg from '@te-digi/styleguide/assets/tyomarkkinatori-en.svg'
import tyomarkkinatoriPrimarySvg from '@te-digi/styleguide/assets/tyomarkkinatori-primary.svg'
import tyomarkkinatoriSvg from '@te-digi/styleguide/assets/tyomarkkinatori.svg'
import tyomarkkinatoriSvPrimarySvg from '@te-digi/styleguide/assets/tyomarkkinatori-sv-primary.svg'
import tyomarkkinatoriSvSvg from '@te-digi/styleguide/assets/tyomarkkinatori-sv.svg'
import tyomarkkinatoriTestEnSvg from '@te-digi/styleguide/assets/tyomarkkinatori-test-en.svg'
import tyomarkkinatoriTestSvSvg from '@te-digi/styleguide/assets/tyomarkkinatori-test-sv.svg'
import tyomarkkinatoriTestSvg from '@te-digi/styleguide/assets/tyomarkkinatori-test.svg'

import { Code } from '../../components/Code'
import { Content } from '../../components/Content'
import { Playground } from '../../components/Playground'
import { Section } from '../../components/Section'

const StyledSvgWrapper = styled.div`
  svg {
    margin: 0 auto;
    max-height: 400px;
    max-width: 400px;
  }
`

const StyledImage = styled(Image)<{ $isDark?: boolean }>`
  background-color: ${props =>
    tokens.color[props.$isDark ? 'dark' : 'neutral-1']};
  border-radius: ${tokens.radius.md};
  display: block;
  margin: 0 auto;
  max-height: 100px;
  max-width: ${tokens.space['layout-xxl']};
  padding: ${tokens.space.sm};
`

const ASSETS = [
  {
    title: '401.svg',
    file: fourZeroOneSvg
  },
  {
    title: '404.svg',
    file: fourZeroFourSvg
  },
  {
    title: 'employment-plan.svg',
    file: employmentPlanSvg
  },
  {
    title: 'favicon.png',
    file: faviconPng
  },
  {
    title: 'favicon-asiantuntija.png',
    file: faviconAsiantuntijaPng
  },
  {
    title: 'home-content-background.jpg',
    file: homeContentBackgroundJpg
  },
  {
    title: 'home-image-section.jpg',
    file: homeImageSectionJpg
  },
  {
    title: 'job-search-start-fi.svg',
    file: jobSearchStartFiSvg
  },
  {
    title: 'te-palvelut-fi.svg',
    file: tePalvelutFiSvg
  },
  {
    title: 'tyomarkkinatori-asiantuntija.svg',
    file: tyomarkkinatoriAsiantuntijaSvg,
    isDark: true
  },
  {
    title: 'tyomarkkinatori-en-primary.svg',
    file: tyomarkkinatoriEnPrimarySvg
  },
  {
    title: 'tyomarkkinatori-en.svg',
    file: tyomarkkinatoriEnSvg,
    isDark: true
  },
  {
    title: 'tyomarkkinatori-primary.svg',
    file: tyomarkkinatoriPrimarySvg
  },
  {
    title: 'tyomarkkinatori.svg',
    file: tyomarkkinatoriSvg,
    isDark: true
  },
  {
    title: 'tyomarkkinatori-sv-primary.svg',
    file: tyomarkkinatoriSvPrimarySvg
  },
  {
    title: 'tyomarkkinatori-sv.svg',
    file: tyomarkkinatoriSvSvg,
    isDark: true
  },
  {
    title: 'tyomarkkinatori-test-en.svg',
    file: tyomarkkinatoriTestEnSvg,
    isDark: true
  },
  {
    title: 'tyomarkkinatori-test-sv.svg',
    file: tyomarkkinatoriTestSvSvg,
    isDark: true
  },
  {
    title: 'tyomarkkinatori-test.svg',
    file: tyomarkkinatoriTestSvg,
    isDark: true
  }
]
// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content heading="Kuvat ja videot">
    <Section title="Komponentteina tarjotut kuvat">
      <Playground WrapperComponent={StyledSvgWrapper}>
        <EmploymentPlanSvg />
      </Playground>
      <Playground WrapperComponent={StyledSvgWrapper}>
        <FourZeroOneSvg />
      </Playground>
      <Playground WrapperComponent={StyledSvgWrapper}>
        <FourZeroFourSvg />
      </Playground>
      <Playground WrapperComponent={StyledSvgWrapper}>
        <JobSearchStartFiSvg />
      </Playground>
      <Playground WrapperComponent={StyledSvgWrapper}>
        <TePalvelutFiSvg />
      </Playground>
      <Playground WrapperComponent={StyledSvgWrapper}>
        <TyomarkkinatoriEnPrimarySvg />
      </Playground>
      <Playground WrapperComponent={StyledSvgWrapper}>
        <TyomarkkinatoriFiPrimarySvg />
      </Playground>
      <Playground WrapperComponent={StyledSvgWrapper}>
        <TyomarkkinatoriSvPrimarySvg />
      </Playground>
      <Playground
        isDark
        WrapperComponent={StyledSvgWrapper}
      >
        <TyomarkkinatoriEnWhiteSvg />
      </Playground>
      <Playground
        isDark
        WrapperComponent={StyledSvgWrapper}
      >
        <TyomarkkinatoriFiWhiteSvg />
      </Playground>
      <Playground
        isDark
        WrapperComponent={StyledSvgWrapper}
      >
        <TyomarkkinatoriSvWhiteSvg />
      </Playground>
      <Playground
        isDark
        WrapperComponent={StyledSvgWrapper}
      >
        <TyomarkkinatoriAsiantuntijaFiWhiteSvg />
      </Playground>
    </Section>
    <Section title="API">
      <Table
        divider="horizontal"
        responsive
        size="sm"
      >
        <TableHeader>
          <TableRow>
            <TableHeaderCell
              scope="col"
              style={{ width: '25%' }}
            >
              Prop
            </TableHeaderCell>
            <TableHeaderCell scope="col">Kuvaus</TableHeaderCell>
            <TableHeaderCell
              scope="col"
              style={{ width: '10%' }}
            >
              Oletus
            </TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell>
              <Code>ariaLabel</Code>
            </TableCell>
            <TableCell>
              <Code>string</Code>
            </TableCell>
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell>
              <Code>className</Code>
            </TableCell>
            <TableCell>
              <Code>string</Code>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableBody>
      </Table>
    </Section>
    <Section title="Kirjaston mukana tulevat tiedostot">
      <Table>
        <TableBody>
          {ASSETS.map(({ title, file, isDark }) => (
            <TableRow key={title}>
              <TableCell
                style={{ width: '1%' }}
                verticalAlign="middle"
              >
                <StyledImage
                  $isDark={isDark}
                  alt=""
                  noMargin
                  src={file}
                />
              </TableCell>
              <TableCell verticalAlign="middle">
                {title}
                <br />
                <Text
                  color="dark"
                  size="sm"
                >
                  @te-digi/styleguide/assets/{title}
                </Text>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Section>
  </Content>
)

export default Page
